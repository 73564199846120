import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["navbar"]

    connect() {
        // Initial adjustment
        this.adjustPadding();
        // Listen for turbo:load events
        document.addEventListener("turbo:load", this.handleTurboLoad.bind(this));
        // Listen for resize events
        window.addEventListener("resize", this.adjustPadding.bind(this));
    }

    disconnect() {
        // Remove the event listeners when the controller is disconnected
        document.removeEventListener("turbo:load", this.handleTurboLoad.bind(this));
        window.removeEventListener("resize", this.adjustPadding.bind(this));
    }

    handleTurboLoad() {
        // Use requestAnimationFrame to ensure this runs last in the render queue
        requestAnimationFrame(() => {
            // Use a small delay to ensure all styles are applied
            setTimeout(() => {
                this.adjustPadding();
            }, 0);
        });
    }

    handleResize() {
        // Debounce the resize event to prevent excessive calls
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            this.adjustPadding();
        }, 50);
    }

    adjustPadding() {
        // Ensure the element exists before trying to access its properties
        const navElement = this.navbarTarget;

        if (navElement) {
            // Get the height of the <nav> element
            const navHeight = navElement.getBoundingClientRect().height;

            // Set the padding-top of the <body> element with !important
            document.body.style.setProperty("padding-top", `${navHeight + 10}px`, "important");
        }
    }
}
