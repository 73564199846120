import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    submit(e) {
        const form = this.element;
        const formData = new FormData(form);
        const toFrame = form.dataset.toFrame;
        // Loading window animation with name e.g. "school_kid-opacity"
        const loading = document.querySelector(`.${toFrame}-opacity`);
        const loadingSpinner = document.querySelector(`.${toFrame}-opacity .spinner-grow`);

        // Add event listener to show loading window
        loading.addEventListener("turbo:before-fetch-request", (event) => {
            loading.classList.remove('opacity-100');
            loading.classList.add('opacity-25');
            loadingSpinner.classList.toggle('d-none');
        });

        const params = new URLSearchParams(formData);
        const newUrl = `${form.action}?${params.toString()}`;

        Turbo.visit(newUrl, {frame: toFrame, action: 'advance'});
        history.pushState({}, "", newUrl);

        // Add event listener to hide loading window
        loading.addEventListener("turbo:before-fetch-response", (event) => {
            loading.classList.remove('opacity-25');
            loading.classList.add('opacity-100');
            loadingSpinner.classList.toggle('d-none');
        });
    }
}
