import ColorPicker from '@stimulus-components/color-picker'

export default class extends ColorPicker {
    connect() {
        this.themeValue = 'monolith' // Override the theme value here
        super.connect()
    }

    // You can override the components options with this getter.
    // Here are the default options.
    get componentOptions() {
        return {
            preview: true,
            hue: true,

            interaction: {
                input: true,
                clear: false,
                save: true,
            },
        }
    }
}
